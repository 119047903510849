<template>
    <div class="Sigsimu">
        <p class="p justify">
            SigSimu是针对薄膜材料的电磁物理性质模拟工具。采用有效质量和有限元紧束缚方法，可以用于模拟在外加电场和磁场下材料中电子的信号响应，包括电子行为导致的电流和磁化等现象、及其对外电磁场的影响。
        </p>
    </div>
</template>

<script>
export default {
  name: 'Sigsimu',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', 'SigSimu')
      }
}
</script>
<style lang="less" scoped>
.Sigsimu{
    margin-bottom:6.25rem;
}
</style>
